let team = [
  {
    title: "Grabación de canciones",
    subtitle: "Graba tus temas en el estudio de Stalow",
    image: "/static/temas.jpg",
    year: "Servicios Stalow Productions",
    link:"/productions/trabajos", 
    web:"",
    facebook: "",
    instagram: "",
    twitter: "",
    youtube: "",
    youtube_id: "",
    soundcloud: "",
    order: -1
  },
  {
    title: "Producción a medida y catálogo de beats",
    subtitle: "Te ayudamos a encontrar la instrumental perfecta para tu tema",
    image: "/static/beats.webp",
    year: "Servicios Stalow Productions",
    link:"/productions/trabajos", 
    web:"",
    facebook: "",
    instagram: "",
    twitter: "",
    youtube: "",
    youtube_id: "",
    soundcloud: "",
    order: -1
  },
  {
    title: "Mezcla y/o Mastering",
    subtitle: "Nos aseguramos que tu tema suene bien en todos los dispositivos",
    image: "/static/mezcla.webp",
    year: "Servicios Stalow Productions",
    link:"/productions/trabajos", 
    web:"",
    facebook: "",
    instagram: "",
    twitter: "",
    youtube: "",
    youtube_id: "",
    soundcloud: "",
    order: -1
  },
  {
    title: "Grabación y/o producción de videoclips",
    subtitle: "Te ayudamos a conseguir el vídeo perfecto para ti",
    image: "/static/clips.jpg",
    year: "Servicios Stalow Productions",
    link:"/productions/trabajos", 
    web:"",
    facebook: "",
    instagram: "",
    twitter: "",
    youtube: "",
    youtube_id: "",
    soundcloud: "",
    order: -1
  },
  {
    title: "Promoción musical",
    subtitle: "Te ayudamos a monetizar tu música i conseguir actuaciones",
    image: "/static/promocio.jpg",
    year: "Servicios Stalow Productions",
    link:"/productions/trabajos", 
    web:"",
    facebook: "",
    instagram: "",
    twitter: "",
    youtube: "",
    youtube_id: "",
    soundcloud: "",
    order: -1
  },
  {
    title: "STALOW ARTIST TEAM",
    subtitle: "Registrate para conseguir actuaciones, descuentos y mucho más",
    image: "/static/pack-rookie.jpg",
    year: "Servicios Stalow Productions",
    link:"/productions/trabajos", 
    web:"",
    facebook: "",
    instagram: "",
    twitter: "",
    youtube: "",
    youtube_id: "",
    soundcloud: "",
    order: -1
  },
  
];

team = team.reduce((acc, val) => {
  if (val.year in acc) {
    acc[val.year].items.push(val);
    acc[val.year].items.sort((f, s) => f.order - s.order);
    if (!acc[val.year].order) acc[val.year].order = 0
    else if (val.yearOrder) acc[val.year].order = val.yearOrder
  } else {
    acc[val.year] = {
      title: val.year,
      order: val.yearOrder,
      items: [val],
    };
  }
  return acc;
}, {});

export default team;
