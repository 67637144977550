<template lang="pug">
  div
    ContentSection(v-for="section in sections", v-bind="section")
</template>

<script>
import ContentSection from './ContentSection';

export default {
  props: {
    sections: { type: Array, default: [] },
  },
  components: {
    ContentSection,
  },
};
</script>
