<template lang="pug">
v-footer(dark="", padless="")
  v-card.black.lighten-1.white--text.text-center(
    flat="",
    tile="",
    width="100%"
  )
    v-card-text
      v-btn.mx-4.white--text(
        v-for="icon in icons",
        :key="icon.icon",
        :href="icon.url",
        target="_blank",
        icon
      )
        v-icon(size="24px")
          | {{ icon.icon }}
    v-divider
    v-card-text.white--text
      | {{ new Date().getFullYear() }} &copy;
      strong.ml-1 STALOW
</template>

<script>
export default {
  data: () => ({
    icons: [
      {
        icon: "mdi-youtube",
        url: "https://www.youtube.com/channel/UCcoEZidpzUgFlm1GHGMuUjw",
      },
      { icon: "mdi-instagram", url: "https://www.instagram.com/stalowfest/" },
    ],
  }),
};
</script>