let artists = [
  
  {
    title: 'STLW Batalla de Gallos',
    subtitle: 'Vive en directo la gran batalla de freestyle del año, donde solo caben las mejores rimas.',
    image: '/static/stlwbatalladegallos.jpg',
    year: '',
    web: '',
    facebook: '',
    instagram: 'stalowfest',
    twitter: 'stalowfest',
    youtube: 'https://www.youtube.com/channel/UCRx0W96YBw2udnMXilX-alA',
    youtube_id: '',
    soundcloud: '',
    order: 0,
  },
  {
    title: 'STLW Skate Party',
    subtitle: null,
    image: '/static/skateandres.jpg',
    year: '',
    web: '',
    facebook: '',
    instagram: 'stalow.academy',
    twitter: 'stalowfest',
    youtube: 'https://www.youtube.com/user/kaosk8artes',
    youtube_id: '',
    soundcloud: '',
    order: 0,
  },
  {
    title: 'Final STLW Batalla De Canciones 2023',
    subtitle: 'Vive la jornada final con lxs mejores artistas y las mejores canciones del año.',
    image: '/static/kpj bdc.jpg',
    year: '',
    web: '',
    facebook: '',
    instagram: 'stalowfest',
    spotify: '',
    twitter: 'stalowfest',
    youtube: 'https://www.youtube.com/channel/UCcoEZidpzUgFlm1GHGMuUjw',
    youtube_id: '',
    soundcloud: '',
    order: -1,
  },
  {
    title: 'Break Draft (Breakdance Battle)',
    subtitle: 'Vamos a disfrutar de un gran evento de breakdance con la primer edición de Break Draft.',
    year: '',
    image: '/static/break17.png', 
    web: '',
    facebook: '',
    instagram: 'https://www.instagram.com/break.draft/',
    twitter: '',
    youtube: '',
    youtube_id: '',
    soundcloud: '',
    order: -1,
  },
  {
    title: 'Graffiti expo (Murs Plàstics)',
    subtitle: null,
    image: '/static/graffiti22.jpg',
    year: '',
    web: '',
    facebook: '',
    instagram: 'mursplastics',
    twitter: '',
    youtube: '',
    youtube_id: '',
    soundcloud: '',
    order: 0,
  },
  {
    title: 'Showcases',
    subtitle: null,
    image: '/static/santa.jpg',
    year: '',
    web: '',
    facebook: '',
    instagram: '',
    twitter: '',
    youtube: 'https://www.youtube.com/user/RapHardoManresa',
    youtube_id: '',
    soundcloud: '',
    order: 0,
  },
];

artists = artists.reduce((acc, val) => {
  if (val.year in acc) {
    acc[val.year].items.push(val);
    acc[val.year].items.sort((f, s) => f.order - s.order);
    if (!acc[val.year].order) acc[val.year].order = 0
    else if (val.yearOrder) acc[val.year].order = val.yearOrder
  } else {
    acc[val.year] = {
      title: val.year,
      order: val.yearOrder,
      items: [val],
    };
  }
  return acc;
}, {});

export default artists;
