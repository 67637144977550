<template lang="pug">
.countdown
  h3.display-1.mb-1 {{ title }}
  flip-countdown(:deadline="date")
</template>

<script>
import FlipCountdown from "vue2-flip-countdown";

export default {
  props: {
    date: String,
    title: String,
  },
  components: {
    FlipCountdown,
  },
};
</script>

<style lang="sass">
.countdown
  padding: 2rem 0
  text-align: center
</style>