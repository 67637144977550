import  { structureData } from '../utils';

let team = [
  {
    title: "PALIW",
    subtitle: "Profeta de la nueva orden del HIP HOP",
    image: "",
    year: "Asociación Stalow",
    web: "",
    facebook: "",
    instagram: "https://www.instagram.com/palunstain/",
    twitter: "",
    youtube: "https://www.youtube.com/channel/UCtYZHSJP5EdE9Wk027TMwrA",
    youtube_id: "NB-G3JRQ0WM",
    soundcloud: "",
    order: -1
  },
  {
    title: "Enric Perramon Freixenet",
    subtitle: "Artista, mentor de respiración y MC.",
    image: "",
    year: "Asociación Stalow",
    web: "",
    facebook: "",
    instagram: "https://www.instagram.com/rarobeats/",
    twitter: "",
    youtube: "https://www.youtube.com/channel/UCtYZHSJP5EdE9Wk027TMwrA",
    youtube_id: "u4edDTti6oI",
    soundcloud: "",
    order: -1
  },
  {
    title: "Ferran Soler Cot",
    subtitle: "Neuro Community Manager, CEO & MC",
    image: "",
    year: "Asociación Stalow",
    web: "",
    facebook: "",
    instagram: "ferransoler_stlw",
    twitter: "",
    youtube: "https://www.youtube.com/channel/UCcoEZidpzUgFlm1GHGMuUjw",
    youtube_id: "zvagxBWnKuU",
    soundcloud: "",
    order: 0
  },
  {
    title: "Alphonso Valdez Thomen",
    subtitle: "Relaciones con artistas y MC",
    image: "",
    year: "Asociación Stalow",
    web: "",
    facebook: "",
    instagram: "_valtho_",
    twitter: "",
    youtube: "https://www.youtube.com/channel/UCcoEZidpzUgFlm1GHGMuUjw",
    youtube_id: "IV4npsscwa8",
    soundcloud: "",
    order: 0
  },
  {
    title: "Clàudia De Juan Ros",
    subtitle: "Bailaria, profesora de danza y speaker",
    image: "",
    year: "Asociación Stalow",
    web: "",
    facebook: "",
    instagram: "www.instagram.com/dejuanros",
    twitter: "",
    youtube: "https://www.youtube.com/channel/UCRLQj94SKBbWz0K-i8rLeGw",
    youtube_id: "zz6iavKLCPk",
    soundcloud: "",
    order: 0
  },
  {
    title: "Jordi Calvo Freixenet",
    subtitle: "Beatmaker & Hiphoppa",
    image: "",
    year: "Asociación Stalow",
    web: "",
    facebook: "",
    instagram: "https://www.instagram.com/aloops__/",
    twitter: "",
    youtube: "",
    youtube_id: "7qXKaiyxz4I",
    soundcloud: "",
    order: 0
  },
  {
    title: "Marc Gómez Escudé",
    subtitle: "Profesor de skate en STALOW ACADEMY",
    image: "",
    year: "Asociación Stalow",
    web: "",
    facebook: "",
    instagram: "https://www.instagram.com/marcgomezescude/",
    twitter: "",
    youtube: "",
    youtube_id: "tghQN50UizI",
    soundcloud: "",
    order: 0
  },
  {
    title: "Ismael López",
    subtitle: "Speaker y MC",
    image: "",
    year: "Asociación Stalow",
    web: "",
    facebook: "",
    instagram: "https://www.instagram.com/jayblunt90/",
    twitter: "",
    youtube: "",
    youtube_id: "DEWkq9GL9Y4",
    soundcloud: "",
    order: 0
  },
  {
    title: "Samuel Della Siega",
    subtitle: "Skater y Artista",
    image: "",
    year: "Asociación Stalow",
    web: "",
    facebook: "",
    instagram: "https://www.instagram.com/sammithesamm/",
    twitter: "",
    youtube: "",
    youtube_id: "gRKHcsniQ1I",
    soundcloud: "",
    order: 0
  },
  {
    title: "Demiurgo",
    subtitle: "Programador responsable de esta web",
    image: "",
    year: "Asociación Stalow",
    web: "",
    facebook: "",
    instagram: "https://www.instagram.com/explore/tags/demiurge/",
    twitter: "",
    youtube: "",
    youtube_id: "OGnQ3Lmvr9I",
    soundcloud: "",
    order: 0
  },
  {
    title: "Monty",
    subtitle: "Miembro de Trio Psicosis y DJ.",
    image: "",
    year: "Asociación Stalow",
    web: "",
    facebook: "",
    instagram: "https://www.instagram.com/montythedoc/",
    twitter: "",
    youtube: "",
    youtube_id: "H9-rcOmDH1o",
    soundcloud: "",
    order: 0
  },
  {
    title: "Esmero Music Barber",
    subtitle: "Barbero Hiphoppa oficial del grupo.",
    image: "",
    year: "Asociación Stalow",
    web: "",
    facebook: "",
    instagram: "https://www.instagram.com/esmero_musicbarber/",
    twitter: "",
    youtube: "",
    youtube_id: "4WLuWgJCgT8",
    soundcloud: "",
    order: 0
  },
  
  {
    title: "Santa Salut + Elane + DAAX",
    subtitle: "Artista Barcelonina de Musica Urbana.",
    image: "",
    year: "Stalow Fest 2019",
    web: "",
    instagram: "https://www.instagram.com/santa.salut/",
    youtube: "https://www.instagram.com/elane.meta/",
    youtube_id: "g94NsA2ox_8",
    soundcloud: "https://www.instagram.com/elane.meta/",
    order: 0
  },
  
  {
    title: "Ricky + Neno + Bobeh ",
    subtitle: "Artistas Gallegos de Musica Urbana.",
    image: "",
    year: "Stalow Fest 2019",
    web: "",
    instagram: "https://www.instagram.com/rickyhombrelibre/",
    youtube: "",
    youtube_id: "y_yCmL_zwqo",
    soundcloud: "",
    order: 0
  },
  {
    title: "VAAX - Hereus del Beat",
    subtitle: "Artista de Musica Urbana de Terrassa.",
    image: "",
    year: "Stalow Fest 2019",
    web: "",
    instagram: "https://www.instagram.com/vaax_hdb/",
    youtube: "",
    youtube_id: "zI9nacGqkvU",
    soundcloud: "",
    order: 0
  },
  {
    title: "STICH TIGER",
    subtitle: "Máximo exponente del Cat G-Funk.",
    image: "",
    year: "Asociación Stalow",
    web: "",
    instagram: "https://www.instagram.com/arnaumercadal/",
    youtube: "",
    youtube_id: "-h_fXGCk6CM",
    soundcloud: "",
    order: 0
  },
  {
    title: "TERONA WOLF",
    subtitle: "Jurado en la STLW Batalla de Gallos 2019.",
    image: "",
    year: "Asociación Stalow",
    web: "",
    instagram: "https://www.instagram.com/teronaleiva/",
    youtube: "",
    youtube_id: "IwUtuVWCVkA",
    soundcloud: "",
    order: 0
  },
  {
    title: "Assek",
    subtitle: "Organizador del colectivo LFD.",
    image: "",
    year: "Asociación Stalow",
    web: "",
    facebook: "",
    instagram: "https://www.instagram.com/alexsg_26/",
    twitter: "",
    youtube: "",
    youtube_id: "QdmNQUyPGgA",
    soundcloud: "",
    order: 0
  },
  {
    title: "BS",
    subtitle: "Ganador de la STLWBatalladeGallos19.",
    image: "",
    year: "Stalow Fest 2019",
    web: "",
    instagram: "https://www.instagram.com/_b.s.9.7_/",
    youtube: "",
    youtube_id: "n7awwb_zvAM",
    soundcloud: "",
    order: 0
  },
  {
    title: "Senyor Broka",
    subtitle: "Participante de la STLWBatalladeGallos19.",
    image: "",
    year: "Stalow Fest 2019",
    web: "",
    instagram: "https://www.instagram.com/senyor_broka3.0/",
    youtube: "",
    youtube_id: "6huIgptIzHI",
    soundcloud: "",
    order: 0
  },
  {
    title: "NP",
    subtitle: "Participante de la STLWBatalladeGallos19.",
    image: "",
    year: "Stalow Fest 2019",
    web: "",
    instagram: "https://www.instagram.com/naavaarro12/",
    youtube: "",
    youtube_id: "3aCNZwgh9Dw",
    soundcloud: "",
    order: 0
  },
  {
    title: "MOMOCKY & ALCA",
    subtitle: "Participantes de STLWBatalladeGallos19.",
    image: "",
    year: "Stalow Fest 2019",
    web: "",
    instagram: "https://www.instagram.com/n___.meister/",
    youtube: "",
    youtube_id: "Hjl71cGBkj0",
    soundcloud: "",
    order: 0
  },
  {
    title: "Ferum",
    subtitle: "Participante de la STLWBatalladeGallos19.",
    image: "",
    year: "Stalow Fest 2019",
    web: "",
    instagram: "https://www.instagram.com/bastianm.cl/",
    youtube: "",
    youtube_id: "Xtr9duG7kJA",
    soundcloud: "",
    order: 0
  },
  {
    title: "Ian Rubio",
    subtitle: "Participante de la STLWBatalladeGallos19.",
    image: "",
    year: "Stalow Fest 2019",
    web: "",
    instagram: "https://www.instagram.com/del.ianrubio/",
    youtube: "",
    youtube_id: "obFD35hr4z4",
    soundcloud: "",
    order: 0
  },
  {
    title: "Angel 7",
    subtitle: "Participante de la STLWBatalladeGallos19.",
    image: "",
    year: "Stalow Fest 2019",
    web: "",
    instagram: "https://www.instagram.com/angeeeel7/",
    youtube: "",
    youtube_id: "jfiNKMglPuQ",
    soundcloud: "",
    order: 0
  },
  {
    title: "Luis Aponte",
    subtitle: "Participante del STLWSkateContest19.",
    image: "",
    year: "Stalow Fest 2019",
    web: "",
    instagram: "https://www.instagram.com/luis_aponte17/",
    youtube: "",
    youtube_id: "IOSLmNpDIms",
    soundcloud: "",
    order: 0
  },
  {
    title: "DENIS",
    subtitle: "Participante del STLWSkateContest19.",
    image: "",
    year: "Stalow Fest 2019",
    web: "",
    instagram: "https://www.instagram.com/rumii_sb/",
    youtube: "",
    youtube_id: "FATKLuhsFbI",
    soundcloud: "",
    order: 0
  },
  {
    title: "ANDRÉS LOPEZ",
    subtitle: "Participante del STLWSkateContest19.",
    image: "",
    year: "Stalow Fest 2019",
    web: "",
    instagram: "https://www.instagram.com/andreslopezbayot/",
    youtube: "",
    youtube_id: "NuU3JrkXt1c",
    soundcloud: "",
    order: 0
  },
  {
    title: "NICOLÁS BUSTOS",
    subtitle: "Participante del STLWSkateContest19.",
    image: "",
    year: "Stalow Fest 2019",
    web: "",
    instagram: "https://www.instagram.com/nicoobustos_/",
    youtube: "",
    youtube_id: "z6830yvaqUU",
    soundcloud: "",
    order: 0
  },
  {
    title: "SAMM DELLA SIEGA",
    subtitle: "Speaker del STLWSkateContest19.",
    image: "",
    year: "Asociación Stalow",
    web: "",
    instagram: "https://www.instagram.com/sammithesamm/",
    youtube: "",
    youtube_id: "BCOCqrLAl5A",
    soundcloud: "",
    order: 0
  },
  {
    title: "AMANDA",
    subtitle: "Participante del STLWFreestyleDance19.",
    image: "",
    year: "Stalow Fest 2019",
    web: "",
    instagram: "https://www.instagram.com/santaniittaa/",
    youtube: "",
    youtube_id: "xWZS34z_C7c",
    soundcloud: "",
    order: 0
  },
  {
    title: "FERRAN NAVARRO",
    subtitle: "Participante del STLWFreestyleDance19.",
    image: "",
    year: "Stalow Fest 2019",
    web: "",
    instagram: "https://www.instagram.com/ferrannavarro__/",
    youtube: "",
    youtube_id: "sYIRg1umcbU",
    soundcloud: "",
    order: 0
  },
  {
    title: "LAURA ISANTA",
    subtitle: "DJ del STLWFreestyleDance19.",
    image: "",
    year: "Stalow Fest 2019",
    web: "",
    instagram: "https://www.instagram.com/lauraisanta/",
    youtube: "",
    youtube_id: "3bAvhZgHlKQ",
    soundcloud: "",
    order: 0
  },
  
  {
    title: "JULIA ISANTA",
    subtitle: "Juez del STLWFreestyleDance19.",
    image: "",
    year: "Stalow Fest 2019",
    web: "",
    instagram: "https://www.instagram.com/juliaisanta/",
    youtube: "",
    youtube_id: "ZxY7k9FVOYM",
    soundcloud: "",
    order: 0
  },
  {
    title: "RNO BEATBOX",
    subtitle: "Participante de la Spanish Beatbox.",
    image: "",
    year: "Stalow Fest 2019",
    web: "",
    instagram: "https://www.instagram.com/rnobeatbox/",
    youtube: "",
    youtube_id: "N866JeWINnE",
    soundcloud: "",
    order: 0
  },
  {
    title: "SAIDAX",
    subtitle: "Participante de la Spanish Beatbox.",
    image: "",
    year: "Stalow Fest 2019",
    web: "",
    instagram: "https://www.instagram.com/saidax_music/",
    youtube: "",
    youtube_id: "c_inm-XUglo",
    soundcloud: "",
    order: 0
  },
  {
    title: "URI NOSE",
    subtitle: "Participante de la Spanish Beatbox.",
    image: "",
    year: "Stalow Fest 2019",
    web: "",
    instagram: "https://www.instagram.com/uri_nose/",
    youtube: "",
    youtube_id: "c7lZJB_lWto",
    soundcloud: "",
    order: 0
  },
  {
    title: "JOSE ENIX",
    subtitle: "Participante de la Spanish Beatbox.",
    image: "",
    year: "Stalow Fest 2019",
    web: "",
    instagram: "https://www.instagram.com/jose_e.n.i.x/",
    youtube: "",
    youtube_id: "C33yYKvYHH4",
    soundcloud: "",
    order: 0
  },
  {
    title: "Makelenller",
    subtitle: "Participante de la Spanish Beatbox.",
    image: "",
    year: "Stalow Fest 2019",
    web: "",
    instagram: "https://www.instagram.com/makelenller/",
    youtube: "",
    youtube_id: "wRM9wQmvXQ8",
    soundcloud: "",
    order: 0
  },
  {
    title: "Fabi Boss",
    subtitle: "Participante de la Spanish Beatbox.",
    image: "",
    year: "Stalow Fest 2019",
    web: "",
    instagram: "https://www.instagram.com/fabibossmusic/",
    youtube: "",
    youtube_id: "BVlzlwpFeIA",
    soundcloud: "",
    order: 0
  },
  {
    title: "Alibeats & Gabyx",
    subtitle: "Participante y jurado de la Spanish.",
    image: "",
    year: "Stalow Fest 2019",
    web: "",
    instagram: "https://www.instagram.com/alibeatsmusic/",
    youtube: "https://www.instagram.com/gabyxbeatbox/",
    youtube_id: "FRyLhHodBmA",
    soundcloud: "",
    order: 0
  },
  {
    title: "JK Sound",
    subtitle: "Participante de la Spanish Beatbox.",
    image: "",
    year: "Stalow Fest 2019",
    web: "",
    instagram: "https://www.instagram.com/jk_sound.bbx/",
    youtube: "",
    youtube_id: "dnQ53kiDr08",
    soundcloud: "",
    order: 0
  },
  {
    title: "Delta Ena",
    subtitle: "Participante de la Spanish Beatbox.",
    image: "",
    year: "Stalow Fest 2019",
    web: "",
    instagram: "https://www.instagram.com/deltaena/",
    youtube: "",
    youtube_id: "4xBo3s7uQpA",
    soundcloud: "",
    order: 0
  },
  {
    title: "Zetauve",
    subtitle: "Participante de la Spanish Beatbox.",
    image: "",
    year: "Stalow Fest 2019",
    web: "",
    instagram: "https://www.instagram.com/zetauvebeatbox/",
    youtube: "",
    youtube_id: "EhJ-hdLaP2U",
    soundcloud: "",
    order: 0
  },
  {
    title: "ASPX",
    subtitle: "Participante de la Spanish Beatbox.",
    image: "",
    year: "Stalow Fest 2019",
    web: "",
    instagram: "https://www.instagram.com/aspxbeatbox/",
    youtube: "",
    youtube_id: "oSr5mkFjV8Y",
    soundcloud: "",
    order: 0
  },
  {
    title: "Cristina Cano",
    subtitle: "Representante del Ayuntamiento.",
    image: "",
    year: "Asociación Stalow",
    web: "",
    instagram: "https://www.instagram.com/manresajove_oficinajovebages/",
    youtube: "",
    youtube_id: "eU4oAR8kGeA",
    soundcloud: "",
    order: 0
  },
  {
    title: "Carlitros",
    subtitle: "Participante de la STLWGraffitiExpo19.",
    image: "",
    year: "Stalow Fest 2019",
    web: "",
    instagram: "https://www.instagram.com/carlitrosperezz/",
    youtube: "",
    youtube_id: "YBH6G_Yw3Q8",
    soundcloud: "",
    order: 0
  },
  {
    title: "Salim & Daniela",
    subtitle: "Miembors del equipo STLW.",
    image: "",
    year: "Asociación Stalow",
    web: "",
    instagram: "https://www.instagram.com/salimachtt/",
    youtube: "https://www.instagram.com/daniela.tuicaru/",
    youtube_id: "Jj7_dE1GOfA",
    soundcloud: "",
    order: 0
  },
];

export default structureData(team);
