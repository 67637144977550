<template lang="pug">
v-container.my-2(fluid)
  v-subheader(v-if="title").mb-1.display-1 {{ title }}
  v-row(dense)
    v-col(v-for="card in items", :key="card.title", md=6, lg=4, sm=12, padding="8px")
      v-card(tile, outlined, width="100%", elevation="5", v-on="card.link ? { click: () => redirect(card.link) } : {}")
        v-img.align-end(v-if="card.image", :src="card.image", height="300px")
        v-lazy: youtube(v-if="card.youtube_id", :video-id="card.youtube_id", player-width="100%", player-height="200px")

        v-card-title.pb-1 {{ card.title }}
        v-card-subtitle.pt-2(v-if="card.subtitle") {{ card.subtitle }}

        v-card-actions
          v-spacer
          v-btn(v-if="card.bandcamp", :to="card.bandcamp", icon)
            v-icon mdi-bandcamp
          v-btn(v-if="card.youtube", :to="card.youtube", icon)
            v-icon mdi-youtube
          v-btn(v-if="card.instagram", :to="card.instagram", icon)
            v-icon mdi-instagram
          v-btn(v-if="card.twitter", :to="card.twitter", icon)
            v-icon mdi-twitter
          v-btn(v-if="card.soundcloud", :to="card.soundcloud", icon)
            v-icon mdi-soundcloud
          v-btn(v-if="card.facebook", :to="card.facebook", icon)
            v-icon mdi-facebook
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: [
        {
          title: "Venga Staliw",
          image: "/static/bg.jpg"
          // youtube_id: 'Iy-dJwHVX84',
        }
      ]
    },
    title: {
      type: String
    }
  },
  methods: {
    redirect(link) {
      this.$router.push(link) ;
    }
  } 
};
</script>
