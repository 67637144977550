
export function structureData(data) {
    return data.reduce((acc, val) => {
        if (val.year in acc) {
          acc[val.year].items.push(val);
          acc[val.year].items.sort((f, s) => f.order - s.order);
          console.log(val.yearOrder)
          if (!acc[val.year].order) acc[val.year].order = 0
          if (val.yearOrder) acc[val.year].order = val.yearOrder
        } else {
          acc[val.year] = {
            title: val.year,
            order: val.yearOrder,
            items: [val],
          };
        }
        return acc;
      }, {});
      
}

