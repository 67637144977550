let artists = [
  
  {
    title: 'Senyor Broka',
    subtitle: null,
    image: '',
    year: '2018',
    web: '',
    facebook: '',
    instagram: 'senyor_broka3.0',
    twitter: '',
    youtube: 'https://www.youtube.com/channel/UCRx0W96YBw2udnMXilX-alA',
    youtube_id: 'cZcFev89Rvo',
    soundcloud: '',
    order: 0,
  },
  {
    title: 'Khaos',
    subtitle: null,
    image: '',
    year: '2018',
    web: '',
    facebook: '',
    instagram: 'ivankhaos',
    twitter: '',
    youtube: 'https://www.youtube.com/user/kaosk8artes',
    youtube_id: 'r8LiZpqm0bo',
    soundcloud: '',
    order: 0,
  },
  {
    title: 'Perro Verde',
    subtitle: null,
    image: '',
    year: '2018',
    web: '',
    facebook: '',
    instagram: 'greendog.perroverde',
    twitter: '',
    youtube: 'https://www.youtube.com/channel/UCRLQj94SKBbWz0K-i8rLeGw',
    youtube_id: '2M4uJ2AVOG8',
    soundcloud: '',
    order: 0,
  },
  {
    title: 'Hardo Meconio',
    subtitle: null,
    image: '',
    year: '2018',
    web: '',
    facebook: '',
    instagram: '',
    twitter: '',
    youtube: 'https://www.youtube.com/user/RapHardoManresa',
    youtube_id: 'wB15KmX6vtc',
    soundcloud: '',
    order: 0,
  },
  {
    title: 'Eman',
    subtitle: null,
    image: '',
    year: '2018',
    web: '',
    facebook: '',
    instagram: 'emanhhreal',
    twitter: '',
    youtube: 'https://www.youtube.com/channel/UCgEZap_AibO5kcsgT9tGO7w',
    youtube_id: '3nMOUwgDydY',
    soundcloud: '',
    order: 0,
  },
  {
    title: 'Kaso',
    subtitle: null,
    image: '',
    year: '2018',
    web: '',
    facebook: '',
    instagram: 'kaso btl',
    twitter: '',
    youtube: 'https://www.youtube.com/channel/UCyNLYprvzXBnqsnCoKQAWwA',
    youtube_id: 'S5qXMSVTzZs',
    soundcloud: '',
    order: 0,
  },
  {
    title: 'Marta B + Bat the Beat',
    subtitle: null,
    image: '',
    year: '2018',
    web: '',
    facebook: '',
    instagram: 'https://www.instagram.com/batthebeat_music/',
    twitter: '',
    youtube: 'https://www.youtube.com/channel/UCuUz_39N9nTpwkeMPaj7ARg',
    youtube_id: '81H57Sn-0x0',
    soundcloud: '',
    order: 0,
  },
  {
    title: 'Smau',
    subtitle: null,
    image: '',
    year: '2018',
    web: '',
    facebook: '',
    instagram: 'smau_liendres_oficial',
    twitter: '',
    youtube: 'https://www.youtube.com/user/guilleisthebest',
    youtube_id: '_M1GfKcYoAE',
    soundcloud: '',
    order: 0,
  },
  {
    title: 'Maldows',
    subtitle: null,
    image: '',
    year: '2018',
    web: '',
    facebook: '',
    instagram: 'maldows_lian3',
    twitter: '',
    youtube: 'https://www.youtube.com/user/lian3studios',
    youtube_id: 'eM4-kY4Gc2s',
    soundcloud: '',
    order: 0,
  },
  {
    title: 'Shaolin Roof',
    subtitle: null,
    image: '',
    year: '2018',
    web: '',
    facebook: '',
    instagram: 'shaolinroof',
    twitter: '',
    youtube: 'https://www.youtube.com/channel/UCbQmn5kVCJmQ4kJqyLnZS1Q',
    youtube_id: 'NL5bBqFkbt0',
    soundcloud: '',
    order: 0,
  },
  {
    title: 'The Almohadons',
    subtitle: null,
    image: '',
    year: '2018',
    web: '',
    facebook: '',
    instagram: 'thealmohadons',
    twitter: '',
    youtube: 'https://www.youtube.com/channel/UCIBdZH2HgTF-emjs7hrA5Ig',
    youtube_id: 'HHNLcEET2dU',
    soundcloud: '',
    order: 0,
  },
  {
    title: 'Jonte',
    subtitle: null,
    image: '',
    year: '2018',
    web: '',
    facebook: '',
    instagram: 'mr.jonte_',
    twitter: '',
    youtube: 'https://www.youtube.com/channel/UCXo_6XgEh3k2QnMKzmYf7gQ',
    youtube_id: '31Hqb_SMhRg',
    soundcloud: '',
    order: 0,
  },
  {
    title: 'Los Valientes',
    subtitle: null,
    image: '',
    year: '2018',
    web: '',
    facebook: '',
    instagram: 'losvalientesband',
    twitter: '',
    youtube: 'https://www.youtube.com/channel/UCuCwFb5a4iRMKXKUOhMhcuA',
    youtube_id: 'mvPG3CcjZvA',
    soundcloud: '',
    order: 0,
  },
  {
    title: 'The Wax',
    subtitle: null,
    image: '',
    year: '2018',
    web: '',
    facebook: '',
    instagram: 'thewaxband',
    twitter: '',
    youtube: 'https://www.youtube.com/channel/UCUiE-OxXakDgQdHrlSe05Hg',
    youtube_id: 'QO5BjyT4DgQ',
    soundcloud: '',
    order: 0,
  },
  {
    title: 'Duman',
    subtitle: null,
    image: '',
    year: '2018',
    web: '',
    facebook: '',
    instagram: 'https://www.instagram.com/duman_oficial/',
    twitter: '',
    youtube: '',
    youtube_id: 'YP6RRxtZFFo',
    soundcloud: '',
    order: 0,
  },
  {
    title: 'Rick Hombre Libre',
    subtitle: null,
    image: '',
    year: 'ARTISTAS STALOW FEST 2019',
    web: '',
    facebook: 'https://www.facebook.com/profile.php?id=100010563480425',
    instagram: 'https://www.instagram.com/rickyhombrelibre/',
    twitter: '',
    youtube: 'https://www.youtube.com/channel/UCqn-Gg8jYzFHWE1E-pLtiIw',
    youtube_id: 'EPWG2svJVNk',
    soundcloud: 'https://soundcloud.com/rickyhombrelibre',
    order: -55,
  },
  {
    title: 'Elane',
    subtitle: null,
    image: '',
    year: 'ARTISTAS STALOW FEST 2019',
    web: '',
    facebook: '',
    instagram: 'https://www.instagram.com/elanemeta/',
    twitter: '',
    youtube: 'https://www.youtube.com/channel/UCavEYze0tzZss1ABJ62d-ew',
    youtube_id: 'xX7lQ1_aD7Y',
    soundcloud: '',
    order: -2,
  },
  {
    title: 'Santa Salut',
    subtitle: null,
    image: '',
    year: 'ARTISTAS STALOW FEST 2019',
    web: '',
    facebook: '',
    instagram: 'https://www.instagram.com/santa.salut/',
    twitter: '',
    youtube: 'https://www.youtube.com/watch?v=XPN92y6lrDI',
    youtube_id: 'XPN92y6lrDI',
    soundcloud: '',
    order: -3,
  },
  {
    title: 'Banda X 1 Dia',
    subtitle: null,
    image: '',
    year: 'ARTISTAS STALOW FEST 2019',
    web: '',
    facebook: '',
    instagram: 'https://www.instagram.com/bandax1dia/',
    twitter: '',
    youtube: 'https://www.youtube.com/watch?v=ptBuWab12GE',
    youtube_id: 'ptBuWab12GE',
    soundcloud: '',
    order: -4,
  },
  {
    title: 'Trio Psicosis',
    subtitle: null,
    image: '',
    year: 'ARTISTAS STALOW FEST 2019',
    web: '',
    facebook: '',
    instagram: 'https://www.instagram.com/triopsicosis/',
    twitter: 'https://twitter.com/triopsicosis?lang=es',
    youtube: 'https://www.youtube.com/channel/UCcoEZidpzUgFlm1GHGMuUjw',
    youtube_id: 'C8M54jYKnP4',
    soundcloud: '',
    order: 10,
  },
];

artists = artists.reduce((acc, val) => {
  if (val.year in acc) {
    acc[val.year].items.push(val);
    acc[val.year].items.sort((f, s) => f.order - s.order);
  } else {
    acc[val.year] = {
      title: val.year,
      items: [val],
      order: val.yearOrder,
    };
  }
  return acc;
}, {});

export default artists;
