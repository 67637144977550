import Vue from 'vue';
import VueRouter from 'vue-router';
import data from '../config/data';

Vue.use(VueRouter);

const routes = [
  data.home,
];

Object.values(data.menu).forEach((section) => {
  Object.values(section).forEach((item) => {
    routes.push({
      ...item,
    });
  });
});

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
