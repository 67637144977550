import  { structureData } from '../utils';

let artists = [  
  {
    title: 'Valtho',
    subtitle: '¿Como quién?',
    image: '',
    year: '',
    yearOrder: -1,
    web: '',
    facebook: '',
    instagram: '',
    twitter: '',
    youtube: '',
    youtube_id: 'l0I3jIx8Z24',
    soundcloud: '',
    order: 0,
  }
];

export default structureData(artists);
