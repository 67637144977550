<template lang="pug">
v-app
  v-app-bar(color="#FFF", dark, src="/static/topbar.png", fixed, ref="navbar")
    template(v-slot:img="{ props }")
      router-link(to="/"): v-img(v-bind="props")

    v-app-bar-nav-icon.d-md-none(@click.stop="drawer = !drawer")

    v-btn.px-0(plain, to="/"): img.nav-logo(src="/static/logo-stalow-png.png")

    template(v-slot:extension)
      v-tabs.d-none.d-md-flex(align-with-title, slider-size="0")
        v-tab(v-for="(item, key) in menu")
          // Show only one link if there are just one element
          v-btn(v-if="Object.keys(item).length === 1", :to="{ path: Object.values(item)[0].path }", plain) {{ key }}

          v-menu(v-else, offset-y, dark)
            template(v-slot:activator="{ on, attrs }")
              v-btn(
                plain,
                width="100%",
                v-bind="attrs",
                v-on="on"
              ) {{ key }}
            v-list
              v-list-item(v-for="(page, key) in item", :key="key")
                v-list-item-title: v-btn(:to="{ path: page.path }", plain) {{ page.name }}

  v-navigation-drawer(
    v-model="drawer",
    fixed,
    left,
    temporary,
    dark,
    src="/static/bg.jpg"
  )
    template(v-for="(item, key) in menu")
      v-list
        v-list-item(
            v-if="Object.keys(item).length === 1"
            :to="{ path: Object.values(item)[0].path }"
          ): v-list-item-content
            v-list-item-title.text-uppercase {{ key }}
        v-list-group(v-else, sub-group)
          template(v-slot:activator)
            v-list-item-content
              v-list-item-title.text-uppercase {{ key }}
          v-list-item(
            v-for="(page, key) in item",
            :key="key",
            :to="{ path: page.path }"
          )
            v-list-item-title(v-text="page.name")

  v-main.main: router-view

  Footer
</template>

<script>
import data from "~/config/data";
import Footer from "~/components/Footer";

export default {
  name: "App",
  components: {
    Footer,
  },

  data: () => ({
    menu: data.menu,
    drawer: false,
  }),
};
</script>

<style lang="sass">
.main
  margin-top: 112px
.nav
  &-logo
    max-width: 200px
    margin-left: 16px
</style>
