let stlw = [
  {
    title: "The STALOW Project",
    subtitle: "Conéctate al HIP-HOP a través de ésta SERIE.",
    image: "/static/the-stalow-project.png",
    link: "/project/temporada1",
    year: "",
    web: "",
    facebook: "",
    instagram: "",
    twitter: "",
    youtube:
      "",
    youtube_id: "",
    soundcloud: "",
    order: 0
  },
  {
    title: "STALOW FEST",
    subtitle: "Conectate al Hip-Hop en todas sus ramas.",
    image: "/static/stalow-fest.jpg",
    year: "",
    link: "/stalowfest",
    web: "",
    facebook: "",
    instagram: "",
    twitter: "",
    youtube: "",
    youtube_id: "",
    soundcloud: "",
    order: -1
  },
  {
    title: "STALOW PRODUCTIONS",
    subtitle: "Producción audiovisual para artistas como tu.",
    image: "/static/stalow-productions.jpg",
    link: "/productions/servicios",
    year: "",
    web: "",
    facebook: "",
    instagram: "",
    twitter: "",
    youtube: "",
    youtube_id: "",
    soundcloud: "",
    order: 0
  },
  {
    title: "STALOW TOUR",
    subtitle: "Calendario anual de eventos Hip-Hop.",
    image: "/static/stlw-tour.jpg",
    link: "/tour/stalowtour",
    year: "",
    web: "",
    facebook: "",
    instagram: "",
    twitter: "",
    youtube: "",
    youtube_id: "",
    soundcloud: "",
    order: 0
  },
  {
    title: "STALOW ACADEMY",
    subtitle: "Aprende a divertirte con el Hip-Hop",
    image: "/static/stalow-academy.jpg",
    link: "/academy/skate",
    year: "",
    web: "",
    facebook: "",
    instagram: "",
    twitter: "",
    youtube: "",
    youtube_id: "",
    soundcloud: "",
    order: 0
  },
  {
    title: "STALOW STARTER PACK",
    subtitle: "Aprende a medrar en el Hip-Hop.",
    image: "/static/stalow-starter-pack.png",
    link: "/productions/starter-pack",
    year: "",
    web: "",
    facebook: "",
    instagram: "",
    twitter: "",
    youtube: "",
    youtube_id: "",
    soundcloud: "",
    order: 0
  }
];

stlw = stlw.reduce((acc, val) => {
  if (val.year in acc) {
    acc[val.year].items.push(val);
    acc[val.year].items.sort((f, s) => f.order - s.order);
    if (!acc[val.year].order) acc[val.year].order = 0
    else if (val.yearOrder) acc[val.year].order = val.yearOrder
  } else {
    acc[val.year] = {
      title: val.year,
      order: val.yearOrder,
      items: [val],
    };
  }
  return acc;
}, {});

export default stlw;
