<template lang="pug">
div
  v-component(
    v-for="comp in components",
    :is="comp.component",
    v-bind="comp.props"
  )
</template>

<script>
import ContentSection from "~/components/ContentSection";
import CountDown from "~/components/CountDown";
import SocialPage from "~/components/SocialPage";

export default {
  props: {
    title: String,
    components: { type: Array, default: [] },
  },
  components: {
    ContentSection,
    CountDown,
    SocialPage,
  },
};
</script>
