<template lang="pug">
div
  SocialSection(
    v-for="key in Object.keys(sections).sort((a, b) => sections[a].order - sections[b].order)",
    v-bind="sections[key]"
  )
</template>

<script>
import SocialSection from "./SocialSection";

export default {
  props: {
    sections: { type: Object }
  },
  components: {
    SocialSection
  }
};
</script>
