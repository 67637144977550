<template lang="pug">
v-container.sec
  //- lazy-component.sec-img-bg: img.mini-cover(:src="image_background" width="100%")
  .sec-wrp(
    :style="'background-image: url(' + image_background + ');'"
  )
    .sec-img--l(v-if="textPosition === 'right'")
      youtube(v-if="video", :video-id="video", player-width="100%")
      lazy-component(v-else-if="image"): img.mini-cover(
        :src="image",
        width="100%"
      )
    //- .sec-img--l(v-if="image"): lazy-component: img.mini-cover(:src="image" width="100%")
    .sec-inf(
      v-bind:class="{ 'text-justify':  !image && !video }",
      :style="'color:' + fontColor + ';'"
    )
      h1(v-if="primary") {{ title }}
      h2(v-else) {{ title }}
      p {{ text }}
      v-btn(v-if="buttonText", :href="buttonLink", target="_blank") {{ buttonText }}

    .sec-img--r(v-if="textPosition === 'left'")
      youtube(v-if="video", :video-id="video", player-width="100%")
      lazy-component(v-else-if="image"): img.mini-cover(
        :src="image",
        width="100%"
      )
</template>

<script>
export default {
  props: {
    primary: Boolean,
    fontColor: String,
    image_background: String,
    video: String,
    image: String,
    title: String,
    text: String, // or any other constructor
    textPosition: String, // right || left
    buttonText: String,
    buttonLink: String
  }
};
</script>

<style lang="sass" scoped>
.sec
  position: relative
  width: 100%
  max-width: 100%
  padding: 0
  &-wrp
    display: flex
    flex-wrap: wrap
    padding: 3rem 1rem
    justify-content: center
    align-items: center
    z-index: 10
    background-position: center center
    background-size: cover
    background-repeat: no-repeat
  &-img
    flex: 1 1 400px
    min-width: 200px
    max-width: 600px
    margin: 1rem 0
    img
      width: 100%
    &--l
      @extend .sec-img
      margin-right: 2rem
    &--r
      @extend .sec-img
      margin-left: 2rem
  &-inf
    min-width: 300px
    max-width: 650px
    &.centered
      margin: 0 auto
      text-align: center
    &.right
      margin-lelf: auto
</style>
