import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import VueYouTubeEmbed from 'vue-youtube-embed';
import VueLazyload from 'vue-lazyload';
import VueGtag from "vue-gtag";


Vue.config.productionTip = true;


Vue.use(VueGtag, {
  config: { 
    id: "G-Z5MVMQM5JY",
  }
});
Vue.use(VueYouTubeEmbed);
Vue.use(VueLazyload, {
  lazyComponent: true,
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
